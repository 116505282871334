<template>
  <v-container fluid>
    <v-row justify="center" v-if="ComConfig">
      <v-dialog v-model="ComConfig" max-width="500">
        <v-card>
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on">
                  <h4>Ajout/transmission des documents relatifs à la préparation des négociations</h4>
                </span>
              </template>
              <span>Ajout/transmission des documents relatifs à la préparation des négociations</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-file-input v-model="cont.file1" multiple label="Joindre la lettre d’invitation à la négociation"
              truncate-length="15">
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-file-input v-model="cont.file2" multiple label="Joindre l’agenda de la négociation " truncate-length="15">
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-file-input v-model="cont.file3" multiple label="Joindre le projet de marché " truncate-length="15">
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="fileAdd()">
              ENVOYER LES DOCUMENTS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on">
                  <h4>Ajout d’une séance de négociation du marché</h4>
                </span>
              </template>
              <span>Ajout d’une séance de négociation du marché</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-autocomplete v-if="this.$store.getters['Auth/getCom']" v-model="attribution.commission" :items="this.$store.getters['Auth/getCom'].filter(function (value, index, arr) {
              return value.type.status == 3
            })
              " item-text="title" item-value="id" outlined
              label="Choisir la commission de négociation en fonction de l’objet du dossier concerné"></v-autocomplete>
            <v-text-field v-model="attribution.title" name="input-7"
              label="Saisir le titre de la séance de négociation"></v-text-field>
            <v-autocomplete v-if="!Dtype" v-model="attribution.document" v-on:change="getDocUser()"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].docs" item-text="object" item-value="id"
              outlined label="Sélectionner le dossier correspondant à la séance de négociation"></v-autocomplete>
            <date-picker label="Date de la séance de negociation" v-model="attribution.start_time" value-type="format"
              format="YYYY-MM-DD H:m:s" :default-value="new Date()" type="datetime" class="mb-2"
              placeholder="Renseigner la date/l’heure de la séance de négociation"></date-picker>
            <v-autocomplete v-if="!Dtype && this.attribution.document" v-model="attribution.user" :items="allUserstoDoc"
              item-text="user.firstname" item-value="user.id" class="my-2" outlined
              label="PROGRAMMER LA SEANCE DE NEGOCIATION"></v-autocomplete>
            <v-btn color="primary" small @click="addContract" class="white--text" block>
              <v-icon small dark>mdi-book-education-outline</v-icon>Programmer la seance de negociation
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol" v-if="profile != 2">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">
                    <h4>Liste détaillée des séances de négociations des marchés</h4>
                  </span>
                </template>
                <span> Liste détaillée des séances de négociations des marchés </span>
              </v-tooltip>
              <v-spacer></v-spacer><v-chip class="ma-2">{{
                this.$store.getters["Auth/get_all_contract"].length
              }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-virtual-scroll :items="this.$store.getters['Auth/get_all_contract']" :item-height="55" height="400">
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large>mdi-book-education-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle><v-chip small label>{{
                        item.user.firstname
                      }}</v-chip></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn v-if="item.configured" @click="seemeeting(item)" depressed small>
                        Acceder
                        <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-if="!item.configured && profile == 3" color="red" @click="seefileAdd(item)" outlined small>
                        <span> </span>
                        <span>TRANSMETTRE LES DOCUMENTS</span>
                        <v-icon color="red" right>mdi-briefcase</v-icon>
                      </v-btn>
                      <v-btn v-if="!item.configured && profile != 3" color="red" disabled outlined small>
                        <span> </span>
                        <span>En attente</span>
                        <v-icon color="red" right>mdi-briefcase</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="12" :md="dynamicCol" v-if="profile == 2">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">
                    <h4>Liste détaillée des séances de négociations des marchés</h4>
                  </span>
                </template>
                <span> Liste détaillée des séances de négociations des marchés </span>
              </v-tooltip>
              <v-spacer></v-spacer><v-chip class="ma-2">{{
                this.$store.getters["Auth/get_all_contract_by_user"].length
              }}</v-chip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-virtual-scroll :items="this.$store.getters['Auth/get_all_contract_by_user']" :item-height="55" height="400">
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large>mdi-book-education-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle><v-chip small label>{{
                        item.user.firstname
                      }}</v-chip></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn v-if="item.configured" @click="seemeeting(item)" depressed small>
                        Acceder
                        <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-if="!item.configured && profile == 3" color="red" @click="seefileAdd(item)" outlined small>
                        <span> </span>
                        <span>TRANSMETTRE LES DOCUMENTS</span>
                        <v-icon color="red" right>mdi-briefcase</v-icon>
                      </v-btn>
                      <v-btn v-if="!item.configured && profile != 3" color="red" disabled outlined small>
                        <span> </span>
                        <span>En attente</span>
                        <v-icon color="red" right>mdi-briefcase</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    ComConfig: false,
    allUserstoDoc: [],
    attribution: {
      title: "",
      start_time: null,
      document: 0,
      user: 0,
      commission: ""
    },
    cont: {
      file1: [],
      file2: [],
      file3: []
    },
    contractObj: "",
    dynamicCol: 6,
    Dtype: false
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    if (this.profile != 3) {
      this.dynamicCol = 12;
    }
    this.user_data = Virt;
    // console.log("Voici les détails ici :", this.user_data);
    // this.someMethod();
    // this.getById();
    this.$store.dispatch("Auth/loadAllContract");
    this.$store.dispatch("Auth/getContractByUser");
    this.$store.dispatch("Auth/loadAllCom");
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),

    // async getById() {
    //   const details = await this.$store.dispatch("Auth/getContractByUser");
    //   console.log("Voici les détails ici :", details);
    // },

  //   async someMethod() {
  // try {
  //   const details = await this.getById(this.user_data.id);
  //   console.log("l'id est :", this.user_data.id);
  //   // console.log("Voici les détails ici :", details);
  // } catch (error) {
  //   console.error("Une erreur s'est produite :", error);
  // }
// },

    async getDocUser() {
      await this.$store.dispatch("Auth/getDoc", this.attribution.document);
      let users = this.$store.getters["Auth/get_selected_doc"].download_list;
      this.allUserstoDoc = users;
      //console.log(users);
    },
    seefileAdd(item) {
      this.ComConfig = !this.ComConfig;
      this.contractObj = item;
    },
    fileAdd() {
      const data = new FormData();
      data.append("contract_id", this.contractObj.id);
      data.append("letter_file", this.cont.file1[0]);
      data.append("agenda_file", this.cont.file2[0]);
      data.append("market_file", this.cont.file3[0]);
      this.$store.dispatch("Auth/contractFileAdd", data);

      this.ComConfig = !this.ComConfig;
    },
    addContract() {
      const data = new FormData();
      if (this.attribution.title) {
        data.append("title", this.attribution.title);
        if (this.Dtype) {
          data.append("notice", this.attribution.document);
        } else {
          data.append("document", this.attribution.document);
        }
        data.append("user_id", this.attribution.user);
        data.append("start_time", this.attribution.start_time);
        data.append("com_id", this.attribution.commission);
        data.append("duration", 160);
        data.append("password", "1234");
        this.$store.dispatch("Auth/addContract", data);
      }
    },
    seemeeting(item) {
      console.log(item);
      this.$router.push({ name: "showContract", params: { id: item.id } });
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}

.mx-datepicker {
  width: 100%;
}

.w-62em {
  height: 62em !important;
  width: 100% !important;
}

.v-card__text {
  width: 100% !important;
}

.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
