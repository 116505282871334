<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Information sur la séance de négociation du marché concerné</h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Titre de la séance de négociation :</b>
            {{ this.$store.getters["Auth/get_selected_contract"].contract.title }}

            <v-spacer></v-spacer>
            <b>Intitulé de la commission de négociation :</b>
            {{ this.$store.getters["Auth/get_selected_contract"].contract.commission.title }}
            <v-spacer></v-spacer>
            <b>Nom de l’attributaire provisoire :</b>
            {{ this.$store.getters["Auth/get_selected_contract"].contract.user.firstname
                + ' ' + this.$store.getters["Auth/get_selected_contract"].contract.user.lastname
              }}
          </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste/nombre/qualité des membres de la commission de négociation</h4>
              </template>
              <span>Liste/nombre/qualité des membres de la commission de négociation</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              {{ this.$store.getters["Auth/get_selected_contract"].users_commission.length }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_selected_contract'].users_commission"
              :item-height="50"
              height="110"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <!-- <v-icon large color="primary" v-show="JSON.parse(localStorage.getItem('userData')).id == item.id">mdi-account-circle</v-icon> -->
                    <v-icon medium>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.user.firstname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user.lastname }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span>{{ item.type.description }}</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-2"
          v-if="profile != 3 && !this.$store.getters['Auth/get_selected_contract'].signature_state"
        >
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h4" v-bind="attrs" v-on="on">Signature du Procès-Verbal de négociation</h4>
              </template>
              <span>Signature du Procès-Verbal de négociation</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="undo" class="white--text" icon>
              <v-icon>
                mdi-backup-restore
              </v-icon>Reprendre la signature
            </v-btn>
          </v-card-title>
          <v-card-text>
            <VueSignaturePad
              class="mx-auto"
              style="border:1px solid red"
              height="120px"
              width="100%"
              :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }"
              ref="signaturePad"
            />

            <div></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="save" class="white--text" block>
              <v-icon class="mr-2" dark>mdi-draw</v-icon>VALIDER LA SIGNATURE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile != 2">
        <v-card>
          <v-card-title>
            <h4>
              Rédaction du Procès-Verbal (PV) de la séance de négociation du marché
              <!--{{ this.$store.getters["Auth/get_selected_contract"].contract_type.description }}-->
            </h4>
            <v-spacer></v-spacer>
            <span
              v-show="!this.$store.getters['Auth/get_selected_contract'].contract.generatedSign"
            >
              <v-btn
                v-show="this.$store.getters['Auth/get_selected_contract'].pv"
                @click.prevent="GenerateSignature()"
                text
                depressed
                class="secondary"
              >
                Generer la liste de presence
              </v-btn>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!this.$store.getters['Auth/get_selected_contract'].pv">
            <DynamicContract
              :key="this.id"
              :contractID="this.id"
              :sessionType="this.$store.getters['Auth/get_selected_contract'].contract_type"
            />
          </v-card-text>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_contract'].pv">
            <EditorViewer
               v-if="profile == 3"
              :isAllowedPrint="true"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_contract'].pv"
            />
            <EditorViewer
               v-if="profile != 3"
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_contract'].pv"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile == 2">
        <v-card>
          <v-card-title>
            <h4>
              Rédaction du Procès-Verbal (PV) de la séance de négociation du marché
              <!--{{ this.$store.getters["Auth/get_selected_contract"].contract_type.description }}-->
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_contract'].pv">
            <EditorViewer
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_contract'].pv"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DynamicContract from "../../../config/DynamicContract";
import EditorViewer from "../../../config/EditorViewer";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { EditorViewer, DynamicContract, DatePicker },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    userData: "",
    fin: {
      start_time: null,
      selected: []
    },
    allSign: [],
    GeneratedSignListCom: "",
    GeneratedSignListSoum: "",
    com: {
      file: [],
      com: 0
    },
    docorNoticeID: 0,
    pv: [],
    ppm: [],
    additif: false
  }),
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.userData = Virt;
    this.$store.dispatch("Auth/getContract", this.id);
    this.$store.dispatch("Auth/loadAllCom");
  },
  methods: {
    comCall() {
      const data = new FormData();
      data.append("com_id", this.com.com);
      data.append("contract_id", this.id);
      data.append("file", this.com.file[0]);
      this.$store.dispatch("Auth/comContractCall", data);
    },
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    async UpdateAttPv(contentReceived, signature) {
      const data = new FormData();
      let content = contentReceived;
      content += signature;
      data.append("file_blob", content);
      data.append("contract_id", this.id);
      console.log(content);
      await this.$store.dispatch("Auth/addPV", data);
      this.$router.go();
    },
    async GenerateSignature() {
      await this.$store.dispatch("Auth/loadContractSignature", this.id);
      this.allSign = this.$store.getters["Auth/get_selected_contract"].signatures;
      this.getThem();
    },
    async getThem() {
      let contener = "";
      let session = this.id;
      // let collected_signature = this.allSign.filter(function(value, index, arr){
      //     return value.eval.id == session;
      // },session);
      let AllCom = this.allSign.filter(function(value, index, arr) {
        return value.com;
      }, session);
      let AllSoum = this.allSign.filter(function(value, index, arr) {
        return value.op;
      }, session);

      AllCom.forEach((element, index, arr) => {
        this.GeneratedSignListCom += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      AllSoum.forEach((element, index, arr) => {
        this.GeneratedSignListSoum += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      let signatureCom = `<p>
      <strong>Annexe 1 : Liste de présence des membres de la commission de négociation</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Fonction/Structure</p></th>
                  <th><p>Qualité du membre de la commission</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListCom}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      let signatureSoum = `<p>
      <strong>Annexe 2 : Liste de présence de l’attributaire du marché</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Structure/Cabinet</p></th>
                  <th><p>Qualité du représentant</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListSoum}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      contener += signatureCom;
      contener += signatureSoum;

      console.log(contener);

      this.UpdateAttPv(this.$store.getters["Auth/get_selected_contract"].pv.file_blob, contener);
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const dataSign = new FormData();
      let userEmail = this.userData.email;
      let ChartTitle = this.$store.getters["Auth/get_selected_contract"].users_commission;
      let UserTitle = ChartTitle.filter(function(value, index, arr) {
        return value.user.email == userEmail;
      }, userEmail);
      dataSign.append("file_blob", data);
      dataSign.append("contract_id", this.id);
      // PROFILE SIGNING
      dataSign.append("is_rep", 0);
      if (this.profile == 2) {
        dataSign.append("is_op", 1);
        dataSign.append("is_com", 0);
      } else {
        dataSign.append("is_op", 0);
        dataSign.append("is_com", 1);
      }
      // DATA TO SEND
      dataSign.append("name", this.userData.firstname);
      dataSign.append("surname", this.userData.lastname);
      dataSign.append("email", this.userData.email);
      dataSign.append("fonction", this.userData.profession);
      if (this.profile == 2) {
        dataSign.append("quality", "Attributaire");
      } else {
        dataSign.append("quality", UserTitle[0].type.description);
      }
      if (!isEmpty) {
        // console.log(UserTitle);
        this.$store.dispatch("Auth/addSignatureContract", dataSign);
      } else {
        //alert swal sign empty
      }
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
